import React from 'react'
import { useDropzone } from 'react-dropzone';

import { blueGrey } from '@mui/material/colors';

const style = {
    dropzone: {
        color: blueGrey[500],
        width: '100%',
        height: 45,
        border: '2px dotted',
    },
}

const ImageForm = (props) => {

    //画像最大枚数
    //const imageMax=5
    //商品画像
    

    const onDrop = React.useCallback((acceptedFiles) => {
        //acceptedFiles.splice(imageMax,acceptedFiles.length)//5件目以降は削除
        props.imagesSet(acceptedFiles)
        const dataUrls = acceptedFiles.map((file) => URL.createObjectURL(file));
        props.imagesPreviewSet(dataUrls)
    }, [props]);

    //最大登録画像枚数
    let max=5
    if(props.imagesCnt){
        max=max-props.imagesCnt
    }
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/jpeg': [], 'image/png': [], 'image/gif': [], } ,maxFiles:max});

    if(max===0){
        return(<></>)
    }else{
        return (
            <>
                <div className="previewzone">
                    {props.imagesPreview &&
                        props.imagesPreview.map((image, i) => (
                            <div key={i}>
                                <img className="imagesPreView" src={image} alt="preview" />
                            </div>
                        ))
                    }
                </div>
                <div {...getRootProps()} style={style.dropzone}>
                    <input {...getInputProps()} />
                    <p className="dropzoneP">
                    {
                        isDragActive
                            ? <>Droping...</>
                            : <>画像をドラッグ＆ドロップかここをクリックして画像を選択して下さい。</>
                    }
                    {
                        props.imagesCnt>0
                        ? <> 残り{max} 枚</>
                        : <> 最大{max} 枚</>
                    }
                    </p>
                </div>
            </>
        )
    }
    
}
export default ImageForm
