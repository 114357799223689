import { useState, useEffect, useCallback } from 'react';

export const useFetchLiquorEdit = (productCode) => {
    //マスタデータリスト
    const [isLoadingEdit,setIsLoadingEdit]=useState(false)
    const [editData, setEditData] = useState([])
    const editDataSet = useCallback((val) => {
        setEditData(val)
    }, [setEditData])
    //マスタ取得
    const Call_API = useCallback(async () => {
        setIsLoadingEdit(true)
        const data = {
            productCode:productCode,
        }
        const url = "https://bahati.work/api/kizen/API_Liquor_Edit_R.php";
        await fetch(url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(data)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }
                return res.json();
            })
            .then((json) => {
                setIsLoadingEdit(false)
                editDataSet(json)
            })
            .catch((reason) => {
                console.log(reason);
            });
    }, [editDataSet,productCode])

    useEffect(() => {
        Call_API()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode])
    return { editData,isLoadingEdit }
}