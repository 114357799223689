import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

//カスタムフック
import { useFetchCountryMaster } from "../hooks/useFetchCountryMaster"

const SelectWhite = styled(Select)(() => ({
    color: '#FFF',
    border: `1px solid #FFF`,
    marginRight: '10px',
    ':focus-within': {
        border: `0px solid #FFF`,
    },
    ':focus': {
        border: `0px solid #FFF`,
    },
}))


export default function CountrySelect(props) {
    const { countryList } = useFetchCountryMaster()
    const handleChange = (event) => {
        props.countryCodeSet(event.target.value)
        console.log(event.target.value)
    }

    return (
        <SelectWhite
            labelId="countryListLabel"
            id="countryList"
            value={props.countryCode}
            label="対象国"
            onChange={handleChange}
        >
            <MenuItem value="start" selected={true}>対象国を選択</MenuItem>
            {countryList.map((data, i) => {
                return (
                    <MenuItem key={i+'country'} value={data.code}>{data.name}</MenuItem>
                )
            })}
        </SelectWhite>
    )
}