import { useState, useEffect, useCallback } from 'react';

export const useFetchCountryMaster = () => {
    //マスタデータリスト
    const [countryList, setCountryList] = useState([])
    const countryListSet = useCallback((val) => {
        setCountryList(val)
    }, [setCountryList])
    //マスタ取得
    const Call_API = useCallback(async () => {
        const url = "https://bahati.work/api/kizen/API_Country_Master_R.php";
        await fetch(url,
            {
                credentials: "same-origin",
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }
                return res.json();
            })
            .then((json) => {
                countryListSet(json)
            })
            .catch((reason) => {
                console.log(reason);
            });
    }, [countryListSet])

    useEffect(() => {
        Call_API()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return { countryList }
}