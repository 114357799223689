import { useState, useEffect, useCallback } from 'react';

export const useFetchImages = (productCode,cnt) => {
    //画像データ取得
    const [imageLinks, setImageLinks] = useState([])
    
    //画像取得
    const Call_API = useCallback(async () => {
        const data = {
            productCode:productCode,
        }
        const url = "https://bahati.work/api/kizen/API_Images_R.php";
        await fetch(url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(data)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }
                return res.json();
            })
            .then((json) => {
                setImageLinks(json)
            })
            .catch((reason) => {
                console.log(reason);
            });
    }, [setImageLinks,productCode])

    useEffect(() => {
        Call_API()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCode,cnt])
    return { imageLinks }
}