import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridActionsCellItem, jaJP } from '@mui/x-data-grid';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import ReportIcon from '@mui/icons-material/Report';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

//カスタムフック
import { useFetchLiquorMaster } from "../hooks/useFetchLiquorMaster"

const LiquorMaster = (props) => {
    const { masterList, isLoadingLiquorMaster } = useFetchLiquorMaster(props.countryCode,props.openLiquor)
    const [listData, setListData] = React.useState(masterList)

    const NoMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            padding: '5px',
            paddingBottom: '0px',
        },
    })

    // アイコンをクリックしたときの処理
    const handleDetailClick = React.useCallback(
        (params) => (event) => {
            event.stopPropagation()
            console.log(params.row.code)
        },
        []
    )
    const handleEditClick = React.useCallback(
        (params) => (event) => {
            event.stopPropagation()
            props.productCodeSet(params.row.code)
            props.liquorFormEditClick()
        },
        [props]
    )
    // 表示するアクションを返す関数です
    const getDetailAction = React.useCallback(
        (params) => [
            <GridActionsCellItem
                icon={<Tooltip title="詳細画面へ"><ArticleIcon /></Tooltip>}
                label="詳細"
                onClick={handleDetailClick(params)}
                color="inherit"
            />
        ],
        [handleDetailClick]
    )
    const getEditAction = React.useCallback(
        (params) => [
            <GridActionsCellItem
                icon={<Tooltip title="編集画面へ"><EditIcon /></Tooltip>}
                label="編集"
                onClick={handleEditClick(params)}
                color="inherit"
            />
        ],
        [handleEditClick]
    )

    const columns = [
        {
            field: 'detailAction',
            headerName: '詳細',
            headerAlign: 'center',
            align: 'center',
            width: 30,
            type: 'actions',
            getActions: getDetailAction
        },
        {
            field: 'editAction',
            headerName: '編集',
            headerAlign: 'center',
            align: 'center',
            width: 30,
            type: 'actions',
            getActions: getEditAction
        },
        {
            field: 'img',
            headerName: '',
            width: 50,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    <NoMaxWidthTooltip placement="right" title={
                        <img
                            className="imagesBig"
                            alt={params.value + 'Big'}
                            src={params.value}
                            onError={(e) => e.target.src = "./img/noimage.png"}
                        />
                    }>
                        <img
                            className="images"
                            src={params.value}
                            alt={params.value}
                            onError={(e) => e.target.src = "./img/noimage.png"}
                        />
                    </NoMaxWidthTooltip>

                </>
            ),
            hideSortIcons: true,     // ソートアイコンを表示しない
            disableExport: true,     // エクスポートに含めないようにする
            disableReorder: true,    // 並べ替えできないようにする
            filterable: true,        // フィルタリングしないようにする
            disableColumnMenu: true,  // カラムメニューを表示しない
        },
        {
            field: 'kind',
            headerName: '酒質',
            width: 100,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'code',
            headerName: '商品コード',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            hide: true,
        },
        {
            field: 'hscode',
            headerName: 'HS',
            headerAlign: 'center',
            width: 100,
            editable: true,
            hide: true,
        },
        {
            field: 'name',
            headerName: '商品名',
            headerAlign: 'center',
            width: 350,
        },
        {
            field: 'notes',
            headerName: '特記',
            width: 30,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value!=="" && <Tooltip title={params.value}><ReportIcon /></Tooltip>}
                </>
            ),
        },
        {
            field: 'sName',
            headerName: '仕入先',
            headerAlign: 'center',
            width: 110,

        },
        {
            field: 'adMini',
            headerName: '住所',
            headerAlign: 'center',
            align: 'center',
            width: 80,

        },
        {
            field: 'lot',
            headerName: 'ﾛｯﾄ',
            width: 50,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'ac',
            headerName: '度数',
            width: 70,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'capa',
            headerName: '容量',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}ml
                </>
            ),
        },
        {
            field: 'container',
            headerName: '荷姿',
            width: 60,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <strong>

                    <Tooltip title={<h3>{params.value}</h3>}>
                        <div className="size">{params.value}</div>
                    </Tooltip>
                </strong>
            ),
        },
        {
            field: 'pp',
            headerName: '一般',
            width: 70,
            headerAlign: 'center',
            align: 'right',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}円
                </>
            ),
        },
        {
            field: 'wp',
            headerName: '卸売',
            width: 70,
            headerAlign: 'center',
            align: 'right',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}円
                </>
            ),
        },
        {
            field: 'sp',
            headerName: '出荷',
            width: 70,
            headerAlign: 'center',
            align: 'right',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}円
                </>
            ),
        },
        {
            field: 'lt',
            headerName: '酒税',
            width: 70,
            headerAlign: 'center',
            align: 'right',
            hide: true,
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}円
                </>
            ),
        },
        {
            field: 'pup',
            headerName: '仕入',
            width: 70,
            headerAlign: 'center',
            align: 'right',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}円
                </>
            ),
        },
        {
            field: 'sw',
            headerName: '単幅',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'sh',
            headerName: '単高',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'sd',
            headerName: '単奥',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'size',
            headerName: '単品ｻｲｽﾞ',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <strong>
                    <div className="size">{params.value[0]}</div>
                    <div className="size">{params.value[2]}</div>
                    <div className="size">{params.value[1]}</div>
                </strong>
            ),

            hide: true,
        },
        {
            field: 'weight',
            headerName: '単品重量',
            width: 75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}g
                </>
            ),
            hide: true,
        },
        {
            field: 'casew',
            headerName: 'ｹｰｽ幅',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'caseh',
            headerName: 'ｹｰｽ高',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'cased',
            headerName: 'ｹｰｽ奥',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}mm
                </>
            ),
            hide: true,
        },
        {
            field: 'caseSize',
            headerName: 'ｹｰｽｻｲｽﾞ',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <strong>
                    <div className="size">{params.value[0]}</div>
                    <div className="size">{params.value[2]}</div>
                    <div className="size">{params.value[1]}</div>
                </strong>
            ),
            hide: true,
        },
        {
            field: 'caseWeight',
            headerName: 'ｹｰｽ重量',
            width: 75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    {params.value.toLocaleString()}kg
                </>
            ),
            hide: true,
        },
        {
            field: 'materials',
            headerName: '原材料',
            width: 150,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <strong>
                    <Tooltip title={<h3>{params.value}</h3>}>
                        <div className="size">{params.value}</div>
                    </Tooltip>
                </strong>
            ),
        },
        {
            field: 'remarks',
            headerName: '商品特徴',
            width: 300,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <strong>
                    <Tooltip title={<h3>{params.value}</h3>}>
                        <div className="size">{params.value}</div>
                    </Tooltip>
                </strong>
            ),
        },
    ];


    React.useEffect(() => {
        if (props.search !== "") {
            const filterData = masterList.filter(value => {
                if (
                    value.name.indexOf(props.search) !== -1
                    || value.kind.indexOf(props.search) !== -1
                    || value.code.indexOf(props.search) !== -1
                    || value.adMini.indexOf(props.search) !== -1
                    || value.materials.indexOf(props.search) !== -1
                    || value.remarks.indexOf(props.search) !== -1
                    || value.sName.indexOf(props.search) !== -1
                ) {
                    return value
                } else {
                    return false
                }
            })
            setListData(filterData)
        } else {
            setListData(masterList)
        }
    }, [props.search, masterList])

    React.useEffect(() => {
        setListData(masterList)
    }, [masterList])

    return (
        <>
            {isLoadingLiquorMaster && <div><i className="fas fa-sync fa-spin"></i>Loading ...</div>}
            {props.countryCode === "start" && <div>対象国を選択して下さい。</div>}
            {props.countryCode !== "start" && !isLoadingLiquorMaster && listData.length < 1 && <div>該当データがありません。</div>}
            {props.countryCode !== "start" && listData.length > 0 && !isLoadingLiquorMaster &&
                <Box sx={{ m: 0, height: '85vh', width: '100%' }}>
                    <DataGrid
                        sx={styles.grid}
                        rowHeight={75}
                        rows={listData}
                        columns={columns}

                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                </Box>
            }
        </>
    )
}
const styles = {
    grid: {
        '.MuiDataGrid-toolbarContainer': {
            borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
        },
        '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
            borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
        },
        // 列ヘッダに背景色を指定
        '.MuiDataGrid-columnHeaders': {
            backgroundColor: blueGrey[400],
            color: '#fff',
        },
        '.MuiDataGrid-columnHeader:focus-within': {
            outlineOffset: -3
        },
    }
}
export default LiquorMaster