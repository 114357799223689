import * as React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Drawer,
    CssBaseline,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'

import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
//colors
import { blueGrey } from '@mui/material/colors';
//icons
import LiquorIcon from '@mui/icons-material/Liquor';
//import WomanIcon from '@mui/icons-material/Woman';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';




//各メニュー
import LiquorMaster from "./LiquorMaster"
import LiquorForm from "./LiquorForm"
import LiquorFormEdit from './LiquorFormEdit';

import SelectBox from "../components/SelectBox"

import '../assets/index.css';

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
//MuiInputBase-root MuiOutlinedInput-root  MuiInputBase-formControl MuiInputBase-adornedEnd css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root
const searchStyle = {
    '& .MuiInputBase-root': {
        color: '#EEE',
        '& fieldset': {
            borderColor: '#FFF',
        },
        '&:hover fieldset': {
            borderColor: '#FFF',
        },
    },
}

const CustomButton = styled(Button)({
    marginRight: '10px',
    paddingLeft:'5px',
    paddingRight:'5px',
    paddingTop:'0px',
    paddingBottom:'0px',
    minHeight:'56px',
    color: '#FFF',
    border: '1px solid',
    backgroundColor: blueGrey[500],
    borderColor: '#FFF',
    '&:hover': {
        backgroundColor: blueGrey[400],
        borderColor: '#FFF',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: blueGrey[300],
        borderColor: '#FFF',
    },
});

const Top = () => {
    const theme = useTheme();
    //drawerメニュ開閉
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    //酒類マスタ画面開閉
    const [openLiquor, setOpenLiquor] = React.useState(true);
    const liquorOpen = () => {
        setOpenLiquor(true);
    };
    const liquorClose = () => {
        setOpenLiquor(false);
    };
    //酒類商品フォーム画面開閉
    const [openLiquorForm, setOpenLiquorForm] = React.useState(false);
    const liquorFormOpen = () => {
        setOpenLiquorForm(true);
    };
    const liquorFormClose = () => {
        setOpenLiquorForm(false);
    };
    //酒類商品編集フォーム画面開閉
    const [openLiquorFormEdit, setOpenLiquorFormEdit] = React.useState(false);
    const liquorFormEditOpen = () => {
        setOpenLiquorFormEdit(true);
    };
    const liquorFormEditClose = () => {
        setOpenLiquorFormEdit(false);
    };

    //絞込みワード
    const [search, setSearch] = React.useState("")
    const [searchFix, setSearchFix] = React.useState("")
    const [typing, setTyping] = React.useState(false)

    const searchSet = React.useCallback((event) => {
        setSearch((event.target.value))
        if (event.target.value === "") setSearchFix("")
    }, [setSearch])

    //対象国データ
    const [countryCode, setCountryCode] = React.useState("start");
    const countryCodeSet = React.useCallback((val) => {
        setCountryCode(val)
    }, [setCountryCode])

    //対象製品コード
    const [productCode, setProductCode] = React.useState("");
    const productCodeSet = React.useCallback((val) => {
        setProductCode(val)
    }, [setProductCode])

    //全閉じ
    const allClose = () => {
        liquorClose()
        liquorFormClose()
        liquorFormEditClose()
    }

    //酒類マスタ表示
    const liquorClick = React.useCallback(() => {
        liquorOpen()
        liquorFormClose()
        liquorFormEditClose()
    },[])
    //酒類商品フォーム表示
    const liquorFormClick = () => {
        liquorFormOpen()
        liquorClose()
        liquorFormEditClose()
    }
    //酒類商品編集フォーム表示
    const liquorFormEditClick = () => {
        liquorFormClose()
        liquorClose()
        liquorFormEditOpen()
    }

    React.useEffect(() => {
        setSearch("")
    }, [countryCode])

    console.log(productCode)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ background: blueGrey[500] }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" onClick={allClose} className="cursor">
                        アプリタイトル
                    </Typography>
                    {(openLiquor === true || openLiquorForm === true || openLiquorFormEdit===true) &&
                        <>
                            <ChevronRightIcon />
                            {(openLiquorForm === true || openLiquorFormEdit===true)
                                ?
                                <Typography variant="h8" noWrap component="div" onClick={liquorClick} className="cursor">酒類</Typography>
                                :
                                <Typography variant="h8" noWrap component="div" onClick={liquorClick}>酒類</Typography>
                            }
                        </>
                    }
                    {openLiquorForm === true &&
                        <>
                            <ChevronRightIcon />
                            <Typography variant="h8" noWrap component="div">
                                商品追加
                            </Typography>
                        </>

                    }
                    {openLiquorFormEdit === true &&
                        <>
                            <ChevronRightIcon />
                            <Typography variant="h8" noWrap component="div">
                                商品編集
                            </Typography>
                        </>

                    }
                    <div className="search">
                        <TextField
                            className="fr"
                            sx={searchStyle}
                            value={search}
                            onChange={searchSet}
                            onBlur={() => setSearchFix(search)}
                            onCompositionStart={() => setTyping(true)}
                            onCompositionEnd={() => setTyping(false)}
                            onKeyDown={e => {
                                if (typing) return
                                setSearchFix(search)
                            }}
                            placeholder="検索"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton><SearchIcon sx={{ color: blueGrey[500] }} /></IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {openLiquor === true &&
                            <>
                                <CustomButton
                                    onClick={() => liquorFormClick()}
                                >
                                    商品追加
                                </CustomButton>
                                <SelectBox
                                    countryCode={countryCode}
                                    countryCodeSet={countryCodeSet}
                                />
                            </>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem key="total" disablePadding>
                        <ListItemButton onClick={liquorClick}>
                            <ListItemIcon>
                                <LiquorIcon />
                            </ListItemIcon>
                            <ListItemText primary="酒類マスタ" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {openLiquor === true &&
                    <LiquorMaster
                        search={searchFix}
                        countryCode={countryCode}
                        openLiquor={openLiquor}
                        liquorFormEditClick={liquorFormEditClick}
                        productCodeSet={productCodeSet}
                    />
                }
                {openLiquorForm === true &&
                    <LiquorForm
                        liquorClick={liquorClick}
                    />
                }
                {openLiquorFormEdit === true &&
                    <LiquorFormEdit
                        productCode={productCode}
                        liquorClick={liquorClick}
                    />
                }
            </Main>
        </Box>
    );
}
export default Top