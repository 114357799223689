import React from 'react'
import {
    FormControl,
    Button,
    Container,
    Stack,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    Modal,
    Box,
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors';


//コンポーネント
import ImageForm from "../components/ImageForm"
//カスタムフック
import { useFetchSupplier } from "../hooks/useFetchSupplier"
import { useFetchLiquorEdit } from "../hooks/useFetchLiquorEdit"
import { useFetchImages } from '../hooks/useFetchImages'

const styles = {
    modal: {
        position: 'absolute',
        top: '30%',
        left: '30%',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        overflowY: 'auto',
        maxHeight: '90%',
    },
}

const LiquorFormEdit = (props) => {
    //削除画像更新用データ
    const [deleteCnt, setDeleteCnt] = React.useState(0)
    //登録済みデータ
    const { editData, isLoadingEdit } = useFetchLiquorEdit(props.productCode)

    //フォーム追加判定フラグ
    const [editFormFlag, setEditFormFlag] = React.useState(true)//編集ボタンのdisable
    //仕入れ先リスト
    const { supplierList } = useFetchSupplier()
    //仕入れ先
    const [supplier, setSupplier] = React.useState("")
    const supplierSet = (event) => {
        setSupplier(event.target.value);
    };
    //商品名
    const [liquorName, setLiquorName] = React.useState("")
    const liquorNameSet = (event) => {
        setLiquorName(event.target.value);
    };
    //酒質
    const [kind, setKind] = React.useState("")
    const kindSet = (event) => {
        setKind(event.target.value);
    };
    //HSコード
    const [hsCode, setHsCode] = React.useState("")
    const hsCodeSet = (event) => {
        setHsCode(event.target.value);
    };
    //アルコール度数
    const [alC, setAlC] = React.useState("")
    const alCSet = (event) => {
        setAlC(event.target.value)
    };
    //ロット
    const [lot, setLot] = React.useState("")
    const lotSet = (event) => {
        setLot(event.target.value);
    };
    //容量
    const [capa, setCapa] = React.useState("")
    const capaSet = (event) => {
        setCapa(event.target.value);
    };
    //荷姿
    const [container, setContainer] = React.useState("")
    const containerSet = (event) => {
        setContainer(event.target.value);
    };
    //一般価格
    const [prePrice, setPrePrice] = React.useState("")
    const prePriceSet = (event) => {
        setPrePrice(event.target.value);
    };
    //卸し価格
    const [whoPrice, setWhoPrice] = React.useState("")
    const whoPriceSet = (event) => {
        setWhoPrice(event.target.value);
    };
    //出荷価格
    const [shiPrice, setShiPrice] = React.useState("")
    const shiPriceSet = (event) => {
        setShiPrice(event.target.value);
    };
    //仕入れ価格
    const [inPrice, setInPrice] = React.useState("")
    const inPriceSet = (event) => {
        setInPrice(event.target.value);
    };
    //酒税
    const [tax, setTax] = React.useState("")
    const taxSet = (event) => {
        setTax(event.target.value);
    };
    //単品幅
    const [sizeW, setSizeW] = React.useState("")
    const sizeWSet = (event) => {
        setSizeW(event.target.value);
    };
    //単品高
    const [sizeH, setSizeH] = React.useState("")
    const sizeHSet = (event) => {
        setSizeH(event.target.value);
    };
    //単品奥
    const [sizeD, setSizeD] = React.useState("")
    const sizeDSet = (event) => {
        setSizeD(event.target.value);
    };
    //単品重量
    const [weight, setWeight] = React.useState("")
    const weightSet = (event) => {
        setWeight(event.target.value);
    };
    //ケース幅
    const [caseSizeW, setCaseSizeW] = React.useState("")
    const caseSizeWSet = (event) => {
        setCaseSizeW(event.target.value);
    };
    //ケース高
    const [caseSizeH, setCaseSizeH] = React.useState("")
    const caseSizeHSet = (event) => {
        setCaseSizeH(event.target.value);
    };
    //ケース奥
    const [caseSizeD, setCaseSizeD] = React.useState("")
    const caseSizeDSet = (event) => {
        setCaseSizeD(event.target.value);
    };
    //ケース重量
    const [caseWeight, setCaseWeight] = React.useState("")
    const caseWeightSet = (event) => {
        setCaseWeight(event.target.value);
    };
    //原材料
    const [materials, setMaterials] = React.useState("")
    const materialsSet = (event) => {
        setMaterials(event.target.value);
    };
    //備考
    const [remarks, setRemarks] = React.useState("")
    const remarksSet = (event) => {
        setRemarks(event.target.value);
    };
    //特記事項
    const [notes, setNotes] = React.useState("")
    const notesSet = (event) => {
        setNotes(event.target.value);
    };

    //商品画像取得
    const { imageLinks } = useFetchImages(props.productCode, deleteCnt)
    //商品画像
    const [images, setImages] = React.useState([])
    const imagesSet = React.useCallback((val) => {
        setImages(val)
    }, [setImages])
    const [imagesPreview, setImagesPreview] = React.useState([])
    const imagesPreviewSet = React.useCallback((val) => {
        setImagesPreview(val)
    }, [setImagesPreview])

    //削除確認画面
    const [openD, setOpenD] = React.useState(false);
    const modalOpenD = () => setOpenD(true)
    const modalCloseD = () => setOpenD(false)

    const addImages = async () => {
        const params = new FormData();
        params.append('productCode', props.productCode)
        params.append('lastFile',imageLinks[imageLinks.length-1])
        //画像イメージ追加
        images.forEach((img) => {
            params.append('img[]', img)
        })

        const url = "https://bahati.work/api/kizen/API_Images_Add.php"
        await fetch(url,
            {
                method: "POST",
                body: params,
            }
        ).catch(error => {
            console.log(error)
        })
    }
    const editForm = async () => {
        addImages()//画像追加
        const params = new FormData();
        params.append('productCode', props.productCode)
        params.append('liquorName', liquorName)
        params.append('supplier', supplier)
        params.append('kind', kind)
        params.append('alC', alC)
        params.append('lot', lot)
        params.append('hsCode', hsCode)
        params.append('capa', capa)
        params.append('container', container)
        params.append('prePrice', prePrice)
        params.append('whoPrice', whoPrice)
        params.append('shiPrice', shiPrice)
        params.append('inPrice', inPrice)
        params.append('tax', tax)
        params.append('sizeW', sizeW)
        params.append('sizeH', sizeH)
        params.append('sizeD', sizeD)
        params.append('weight', weight)
        params.append('caseSizeW', caseSizeW)
        params.append('caseSizeH', caseSizeH)
        params.append('caseSizeD', caseSizeD)
        params.append('caseWeight', caseWeight)
        params.append('materials', materials)
        params.append('remarks', remarks)
        params.append('notes', notes)

        const url = "https://bahati.work/api/kizen/API_Liquor_Edit.php"
        await fetch(url,
            {
                method: "POST",
                body: params,
            }
        ).then(response => {

            props.liquorClick()
        }).catch(error => {
            console.log(error)
        })
    }
    const deleteForm = async () => {
        const params = new FormData();
        params.append('productCode', props.productCode)

        const url = "https://bahati.work/api/kizen/API_Liquor_Delete.php"
        await fetch(url,
            {
                method: "POST",
                body: params,
            }
        ).then(response => {

            props.liquorClick()
        }).catch(error => {
            console.log(error)
        })
    }

    const deleteImage = (image) => {
        setDeleteCnt(deleteCnt + 1)
        const data = {
            image: image,
        }
        const url = "https://bahati.work/api/kizen/API_Images_Delete.php";
        fetch(url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(data)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }
            })
            .catch((reason) => {
                console.log(reason);
            })
    }

    //編集フォームに初期値セット
    React.useEffect(() => {
        if (!isLoadingEdit && editData.length) {
            setSupplier(editData[0].sCode)
            setLiquorName(editData[0].name)
            setKind(editData[0].kind)
            setHsCode(editData[0].hscode)
            setAlC(editData[0].ac)
            setLot(editData[0].lot)
            setCapa(editData[0].capa)
            setContainer(editData[0].container)
            setPrePrice(editData[0].pp)
            setWhoPrice(editData[0].wp)
            setShiPrice(editData[0].sp)
            setInPrice(editData[0].pup)
            setTax(editData[0].lt)
            setSizeW(editData[0].sw)
            setSizeH(editData[0].sh)
            setSizeD(editData[0].sd)
            setWeight(editData[0].weight)
            setCaseSizeW(editData[0].casew)
            setCaseSizeH(editData[0].caseh)
            setCaseSizeD(editData[0].cased)
            setCaseWeight(editData[0].caseWeight)
            setMaterials(editData[0].materials)
            setRemarks(editData[0].remarks)
            setNotes(editData[0].notes)

            console.log(editData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingEdit])

    React.useEffect(() => {
        if (liquorName !== "" && supplier !== "") {
            setEditFormFlag(false)
        } else {
            setEditFormFlag(true)
        }
    }, [liquorName, supplier])

    console.log(imageLinks[imageLinks.length-1])


    return (
        <Container maxWidth="lg">
            <Stack spacing={1}>
                <form noValidate autoComplete="off">
                    <FormControl>
                        <InputLabel id="supplierListlabel">仕入先</InputLabel>
                        <Select
                            labelId="supplierListlabel"
                            id="supplierList"
                            value={supplier}
                            label="仕入先"
                            onChange={supplierSet}
                            style={{ width: 200 }}
                        >
                            {supplierList.map((data, i) =>
                                <MenuItem key={i + 'supplier'} value={data.scode}>{data.sname}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ marginLeft: '10px' }}
                        style={{ width: 500 }}
                        label="商品名"
                        type="text"
                        value={liquorName}
                        onChange={liquorNameSet}
                    />
                    <FormControl sx={{ marginLeft: '10px' }}>
                        <InputLabel id="kindlabel">酒質</InputLabel>
                        <Select
                            labelId="kindlabel"
                            id="kindList"
                            value={kind}
                            label="酒質"
                            onChange={kindSet}
                            style={{ width: 200 }}
                        >
                            <MenuItem value={'純米大吟醸'}>純米大吟醸</MenuItem>
                            <MenuItem value={'純米吟醸'}>純米吟醸</MenuItem>
                            <MenuItem value={'純米酒'}>純米酒</MenuItem>
                            <MenuItem value={'清酒'}>清酒</MenuItem>
                            <MenuItem value={'梅酒'}>梅酒</MenuItem>
                            <MenuItem value={'甲類焼酎'}>甲類焼酎</MenuItem>
                            <MenuItem value={'ウイスキー'}>ウイスキー</MenuItem>
                            <MenuItem value={'リキュール'}>リキュール</MenuItem>
                            <MenuItem value={'ウオッカ'}>ウオッカ</MenuItem>
                            <MenuItem value={'その他'}>その他</MenuItem>
                        </Select>
                    </FormControl>
                </form>

                <form noValidate autoComplete="off">
                    <TextField
                        label="HSコード"
                        type="text"
                        value={hsCode}
                        onChange={hsCodeSet}
                        style={{ width: 100 }}
                    />
                    <TextField
                        sx={{ marginLeft: '10px' }}
                        label="度数"
                        type="text"
                        value={alC}
                        onChange={alCSet}
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        style={{ width: 100 }}
                    />
                    <div className="formUnit">%</div>
                    <TextField
                        sx={{ marginLeft: '10px' }}
                        label="ロット"
                        type="text"
                        value={lot}
                        onChange={lotSet}
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        style={{ width: 100 }}
                    />
                    <div className="formUnit">本入</div>
                    <TextField
                        sx={{ marginLeft: '10px' }}
                        label="容量"
                        type="text"
                        value={capa}
                        onChange={capaSet}
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        style={{ width: 100 }}
                    />
                    <div className="formUnit">ml</div>
                    <TextField
                        sx={{ marginLeft: '10px' }}
                        label="荷姿"
                        type="text"
                        value={container}
                        onChange={containerSet}
                        style={{ width: 150 }}
                    />
                </form>
                <div>
                    <div className="formSubTitle">価格</div>
                    <form noValidate autoComplete="off">
                        <TextField
                            label="一般"
                            type="text"
                            value={prePrice}
                            onChange={prePriceSet}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            style={{ width: 100 }}
                        />
                        <div className="formUnit">円</div>
                        <TextField
                            sx={{ marginLeft: '10px' }}
                            label="卸し"
                            type="text"
                            value={whoPrice}
                            onChange={whoPriceSet}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            style={{ width: 100 }}
                        />
                        <div className="formUnit">円</div>
                        <TextField
                            sx={{ marginLeft: '10px' }}
                            label="出荷"
                            type="text"
                            value={shiPrice}
                            onChange={shiPriceSet}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            style={{ width: 100 }}
                        />
                        <div className="formUnit">円</div>
                        <TextField
                            sx={{ marginLeft: '10px' }}
                            label="仕入"
                            type="text"
                            value={inPrice}
                            onChange={inPriceSet}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            style={{ width: 100 }}
                        />
                        <div className="formUnit">円</div>
                        <TextField
                            sx={{ marginLeft: '10px' }}
                            label="酒税"
                            type="text"
                            value={tax}
                            onChange={taxSet}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            style={{ width: 100 }}
                        />
                        <div className="formUnit">円</div>
                    </form>
                </div>
                <div>
                    <div className="fl">
                        <div className="formSubTitle">単品サイズ</div>
                        <form noValidate autoComplete="off">
                            <TextField
                                label="幅"
                                type="text"
                                value={sizeW}
                                onChange={sizeWSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="高さ"
                                type="text"
                                value={sizeH}
                                onChange={sizeHSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="奥行"
                                type="text"
                                value={sizeD}
                                onChange={sizeDSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="重量"
                                type="text"
                                value={weight}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                onChange={weightSet}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">g</div>
                        </form>
                    </div>
                    <div className="fl lm">
                        <div className="formSubTitle">ケースサイズ</div>
                        <form noValidate autoComplete="off">
                            <TextField
                                label="幅"
                                type="text"
                                value={caseSizeW}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                onChange={caseSizeWSet}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="高さ"
                                type="text"
                                value={caseSizeH}
                                onChange={caseSizeHSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="奥行"
                                type="text"
                                value={caseSizeD}
                                onChange={caseSizeDSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">mm</div>
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                label="重量"
                                type="text"
                                value={caseWeight}
                                onChange={caseWeightSet}
                                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                style={{ width: 100 }}
                            />
                            <div className="formUnit">kg</div>
                        </form>
                    </div>
                </div>

                <TextField
                    label="原材料"
                    type="text"
                    value={materials}
                    onChange={materialsSet}
                />

                <TextField
                    fullWidth
                    label="商品特徴"
                    type="text"
                    value={remarks}
                    onChange={remarksSet}
                    multiline
                    rows={4}
                />

                <TextField
                    label="特記事項"
                    type="text"
                    value={notes}
                    onChange={notesSet}
                />

                <div className="previewzone">
                    {imageLinks &&
                        imageLinks.map((image, i) => (
                            <div key={i}>
                                <img className="imagesPreView" src={image} alt="preview" />
                                <div>
                                    <Button
                                        sx={{
                                            background: red[500],
                                            '&:hover': {
                                                backgroundColor: red[400],
                                            }
                                        }}
                                        variant="contained"
                                        size="small"
                                        onClick={() => deleteImage(image)}>
                                        削除
                                    </Button>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <ImageForm
                    imagesSet={imagesSet}
                    imagesPreview={imagesPreview}
                    imagesPreviewSet={imagesPreviewSet}
                    imagesCnt={imageLinks.length}
                />

                <Button
                    sx={{
                        background: blueGrey[500],
                        '&:hover': {
                            backgroundColor: blueGrey[400],
                        }
                    }}
                    variant="contained" size="large"
                    onClick={editForm}
                    disabled={editFormFlag}
                >
                    編集
                </Button>
                <Button
                    sx={{
                        background: red[500],
                        '&:hover': {
                            backgroundColor: red[400],
                        }
                    }}
                    variant="contained" size="large"
                    onClick={modalOpenD}
                >
                    削除
                </Button>
            </Stack>
            <Modal
                open={openD}
                onClose={modalCloseD}
            >
                <Box sx={styles.modal}>
                    <div className="modalContents">
                        <div>{liquorName}を削除しますか？</div>
                    </div>
                    <div className="fr">
                        <Button
                            sx={{
                                background: blueGrey[500],
                                '&:hover': {
                                    backgroundColor: blueGrey[400],
                                },
                                marginLeft: '10px',
                            }}
                            variant="contained" size="large"
                            onClick={modalCloseD}
                        >
                            キャンセル
                        </Button>
                    </div>
                    <div className="fr">
                        <Button
                            sx={{
                                background: red[500],
                                '&:hover': {
                                    backgroundColor: red[400],
                                }
                            }}
                            variant="contained" size="large"
                            onClick={deleteForm}
                        >
                            削除
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Container>
    )
}
export default LiquorFormEdit