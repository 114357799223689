import React from 'react';
import Top from "./template/Top"
function App() {
  return (
    <>
      <Top />
    </>
  )
}

export default App;
