import { useState, useEffect, useCallback } from 'react';

export const useFetchLiquorMaster = (countryCode,openLiquor) => {
    const [isLoadingLiquorMaster, setIsLoadingLiquorMaster] = useState(true)
    //マスタデータリスト
    const [masterList, setMasterList] = useState([])
    const masterListSet = useCallback((val) => {
        setMasterList(val)
    }, [setMasterList])
    //マスタ取得
    const Call_API = useCallback(async () => {
        setIsLoadingLiquorMaster(true)
        const data = {
            countryCode:countryCode,
        }
        const url = "https://bahati.work/api/kizen/API_Liquor_Master_R.php";
        await fetch(url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(data)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }
                return res.json();
            })
            .then((json) => {
                console.log(json)
                masterListSet(json)
                setIsLoadingLiquorMaster(false)
            })
            .catch((reason) => {
                console.log(reason);
            });
    }, [masterListSet,countryCode])

    useEffect(() => {
        Call_API()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode,openLiquor])
    return { masterList,isLoadingLiquorMaster }
}